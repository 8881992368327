import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "./../components/Loader/index";

const Homepage = lazy(() => import("../pages/Home"));
const SettingsPage = lazy(() => import("../pages/Settings"));

export const Router = () => {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader />}>
            <Homepage />
          </Suspense>
        }
      />
      <Route
        path="settings"
        element={
          <Suspense fallback={<Loader />}>
            <SettingsPage />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
