import { Box, CssBaseline } from "@mui/material";
import { Suspense } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router/index";
import Loader from "./components/Loader";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <CssBaseline />
      <Box id="app">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Box>
    </Suspense>
  );
}

export default App;
